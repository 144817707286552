import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { Minus, Plus } from "lucide-react";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import FAQ from "~/types/FAQ";
import { localeAttr } from "~/utils/helper";

export default function QuestionsList({
  questions,
  className,
  locale,
  q,
}: {
  questions: FAQ[];
  className?: string;
  locale: string;
  q?: string;
}) {
  return (
    <div className={className}>
      <Accordion
        className="relative z-10 w-full space-y-[10px]"
        allowZeroExpanded
      >
        {questions
          .filter((val) => {
            if (!q) return true;
            return (
              val.attributes.question.toLowerCase().includes(q.toLowerCase()) ||
              JSON.stringify(val.attributes.answer)
                .toLowerCase()
                .includes(q.toLowerCase()) ||
              val.attributes.question_ar
                .toLowerCase()
                .includes(q.toLowerCase()) ||
              JSON.stringify(val.attributes.answer_ar)
                .toLowerCase()
                .includes(q.toLowerCase())
            );
          })
          .map((faq) => {
            return (
              <AccordionItem className="w-full rounded-xl bg-white px-6 py-[18px]">
                <AccordionItemHeading>
                  <AccordionItemButton className="flex cursor-pointer justify-between font-bold text-Secondary-Dark-Blue-db-500 md:text-lg">
                    <AccordionItemState>
                      {({ expanded }) => (
                        <div className="flex w-full justify-between gap-2.5">
                          <h4>
                            {localeAttr(faq.attributes, "question", locale)}
                          </h4>
                          <span className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg border-2 border-Secondary-Dark-Blue-db-500 p-[2px]">
                            {expanded ? <Minus /> : <Plus />}
                          </span>
                        </div>
                      )}
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="[&_*]:[all:revert]">
                  <BlocksRenderer
                    content={localeAttr(faq.attributes, "answer", locale)}
                  />
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
      </Accordion>
    </div>
  );
}
